// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-category-js": () => import("../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-image-sizer-js": () => import("../src/pages/admin/image-sizer.js" /* webpackChunkName: "component---src-pages-admin-image-sizer-js" */),
  "component---src-pages-browse-js": () => import("../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-grid-experiment-js": () => import("../src/pages/grid-experiment.js" /* webpackChunkName: "component---src-pages-grid-experiment-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

